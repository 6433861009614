// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-jsx": () => import("../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-entities-article-page-jsx": () => import("../src/entities/article/page.jsx" /* webpackChunkName: "component---src-entities-article-page-jsx" */),
  "component---src-entities-article-template-jsx": () => import("../src/entities/article/template.jsx" /* webpackChunkName: "component---src-entities-article-template-jsx" */),
  "component---src-entities-topic-page-jsx": () => import("../src/entities/topic/page.jsx" /* webpackChunkName: "component---src-entities-topic-page-jsx" */),
  "component---src-entities-topic-template-jsx": () => import("../src/entities/topic/template.jsx" /* webpackChunkName: "component---src-entities-topic-template-jsx" */),
  "component---src-entities-testimony-page-jsx": () => import("../src/entities/testimony/page.jsx" /* webpackChunkName: "component---src-entities-testimony-page-jsx" */),
  "component---src-entities-testimony-template-jsx": () => import("../src/entities/testimony/template.jsx" /* webpackChunkName: "component---src-entities-testimony-template-jsx" */),
  "component---src-entities-author-page-jsx": () => import("../src/entities/author/page.jsx" /* webpackChunkName: "component---src-entities-author-page-jsx" */),
  "component---src-entities-author-template-jsx": () => import("../src/entities/author/template.jsx" /* webpackChunkName: "component---src-entities-author-template-jsx" */),
  "component---src-entities-node-page-jsx": () => import("../src/entities/node/page.jsx" /* webpackChunkName: "component---src-entities-node-page-jsx" */),
  "component---src-entities-node-template-jsx": () => import("../src/entities/node/template.jsx" /* webpackChunkName: "component---src-entities-node-template-jsx" */),
  "component---src-pages-404-jsx": () => import("../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

