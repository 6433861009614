import React from 'react'
import PropTypes from 'prop-types'
import { isMobile, isTablet } from 'react-device-detect'
import posed, { PoseGroup } from 'react-pose'

const timeout = 200
const duration = 500

class Transition extends React.PureComponent {
  render () {
    const {
      children,
      location: { pathname }
    } = this.props

    const RoutesContainer = posed.div({
      enter: {
        opacity: 1,
        delay: timeout,
        delayChildren: timeout,
        transition: { duration }
      },
      exit: {
        opacity: 0
      }
    })

    return isMobile || isTablet ? (
      children
    ) : (
      <PoseGroup>
        <RoutesContainer key={pathname}>{children}</RoutesContainer>
      </PoseGroup>
    )
  }
}

export default Transition

Transition.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string]),
  location: PropTypes.shape({}).isRequired
}

Transition.defaultProps = {
  children: null
}
